.documents-container {
  @media (max-width: 1195px) {
    border-radius: 0 !important;
  }

  .main-container {
    @media (max-width: 960px) {
      padding: 0;
    }
  }
}

.documents {
  padding: toRem(50px) 0 toRem(0px);
  display: flex;
  flex-direction: column;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: toRem(16px);
    margin: auto;
  }

  &__item {
    padding: toRem(25px) toRem(20px) toRem(35px);
    background: rgba(255,255,255,.3);
    text-align: center;
    border-radius: toRem(30px);
    z-index: 2;
    position: relative;
    width: toRem(290px);

    &--flag {
      img {
        height: 60px;
        width: auto;
      }
    }

    & > svg {
      position: absolute;
      width: 100%;
      height: auto;
      z-index: -1;
    }

    &.--first {
      & > svg {
        width: 90%;
        bottom: 0;
        left: toRem(-10px);
      }
    }

    &.--second {
      & > svg {
        width: 90%;
        bottom: 0;
        right: toRem(-20px);
      }
    }

    &.--third {
      & > svg {
        width: 120%;
        left: 50%;
        top: 65%;
        translate: -50% -50%;
      }
    }

    &--image {
      width: toRem(165px);
      background: #fff;
      padding: toRem(6px);
      border-radius: toRem(10px);
      margin: auto;
      object-fit: cover;

      img {
        width: 100%;
        aspect-ratio: 210/297;
      }
    }

    &--name {
      color: #F0F0F8;
      font-size: toRem(16.493px);
      font-weight: 700;
      line-height: 110%;
      margin-bottom: toRem(30px);
    }
  }

  @media (max-width: 960px) {
    overflow-y: hidden !important;
    overflow-x: auto;
    padding: toRem(50px) toRem(15px) toRem(0px);
    &::-webkit-scrollbar {
      display: none;
    }
  }
}