.tasks {
  &__grid {
    justify-content: center;
    gap: 0 toRem(45px);
    margin: toRem(45px) toRem(-15px) 0;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      gap: toRem(45px) toRem(45px);
    }

    @media (max-width: 768px) {
      .slick-list {
        margin: toRem(-60px) 0 !important;
        padding: toRem(60px) 0 !important;
      }
    }

    &.--row-2 {
      margin-top: 0;
    }

    .slick-slide {
      padding: 0 toRem(20px);
    }
  }

  &__item {
    position: relative;
    width: toRem(343px);
    min-width: toRem(343px);
    height: toRem(412px);
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @media (max-width: 390px) {
      width: toRem(300px);
      min-width: toRem(300px);
      height: toRem(360px);
    }

    & > img,
    & > svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;

      path {
        fill: #F3F6FD;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url('../assets/img/shield.png') center no-repeat;
      background-size: contain;
      scale: 1.4;
    }

    &--content {
      width: toRem(232px);
      height: toRem(192px);
      box-shadow:
        toRem(8px) toRem(8px) toRem(16px) toRem(0px) var(--shadow-down) inset, 
        toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) var(--shadow-up) inset;
      border-radius: toRem(50px);
      margin-top: toRem(40px);
      position: relative;

      @media (max-width: 390px) {
        width: toRem(200px);
        height: toRem(170px);
      }
    }

    &--image {
      background: var(--icon-background-color);
      border-radius: 50%;
      position: absolute;
      width: toRem(142px);
      height: toRem(142px);
      top: 0;
      left: 50%;
      translate: -50% -40%;
      
      @media (max-width: 390px) {
        width: toRem(120px);
        height: toRem(120px);
      }

      img {
        width: 100%;
      }
    }

    &--text {
      padding: toRem(30px) toRem(30px);
      font-size: toRem(16px);
      font-weight: 600;
      color: var(--text-color);
      text-align: center;
      position: absolute;
      bottom: 0;
      width: 100%;
      @media (max-width: 390px) {
        font-size: toRem(14px);
        padding: toRem(30px) toRem(24px);
      }
    }
  }
}