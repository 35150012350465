.products {
  padding-bottom: 0;

  &.--tu,
  &.--en {
    .products-container__block--description {
      font-size: toRem(16px);
    }
  }

  .prod-container {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &-container {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: toRem(30px);
    margin-top: toRem(80px);

    @media (max-width: 768px) {
      display: block;
      gap: toRem(50px) toRem(10px);
      margin-top: toRem(40px);
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: toRem(15px);
      flex-wrap: wrap;

      @media (max-width: 768px) {
        display: contents;
      }

      &.--row-2 {
        padding: 0 toRem(60px);
      }

      .slick-list {
        padding: 60px 0 !important;
        margin: -60px 0 !important;
      }

      .slick-slide {
        padding: 0 toRem(5px);
      }

      .slick-track {
        display: flex !important;
      }
    }

    &__block {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: toRem(10px);
      color: var(--main-color);
      max-width: toRem(340px);
      //background: rgba(240, 240, 248, 1);
      background: transparent;
      box-shadow: 18px 18px 75px 0px rgba(180, 193, 213, 0.2), -18px -18px 45px 0px rgba(255, 255, 255, 0.2);
      border-radius: toRem(45px);
      padding: toRem(40px) toRem(35px);

      &--logo {
        margin-bottom: toRem(1px);

        & > svg {
          height: toRem(115px);
          width: auto;

          @media (max-width: 540px) {
            height: toRem(75px);
          }
        }
      }

      & > svg {
        height: toRem(37px);
        width: auto;

        @media (max-width: 540px) {
          height: toRem(22px);
        }
      }

      &--name {
        background: var(--main-color);
        color: var(--text-contrast);
        font-size: toRem(15.973px);
        font-weight: 700;
        line-height: 111%;
        padding: toRem(1px) toRem(5px);
        border-radius: toRem(5px);
      }

      &--description {
        color: #006261;
        text-align: center;
        font-size: toRem(20.454px);
        font-weight: 700;
        margin-top: toRem(18px);
        padding-top: toRem(16px);
        position: relative;
        max-width: toRem(262px);

        @media (max-width: 768px) {
          font-size: 16px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          translate: -50% 0;
          width: toRem(90px);
          height: toRem(2px);
          background: rgba(20, 150, 153, 0.3);
        }
      }
    }
  }
}

.product-blocks {
  display: flex;
  flex-direction: column;
  gap: toRem(60px);
  
  @media (max-width: 540px) {
    gap: 0;
  }
}

.product-block {
  border-radius: toRem(30px);
  padding: toRem(110px) toRem(60px);
  display: flex;
  flex-direction: column;

  @media (max-width: 1190px) {
    border-radius: 0;
  }

  @media (max-width: 768px) {
    padding: toRem(60px) toRem(0px);
  }

  @media (max-width: 540px) {
    padding-bottom: 0;
  }

  &__number {
    width: toRem(88px);
    height: toRem(88px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: toRem(-15px);
    right: toRem(5px);
    background: var(--main-color);
    color: var(--text-contrast);
    font-size: toRem(52px);
    line-height: 100%;
    font-weight: 700;

    @media (max-width: 768px) {
      position: static;
      width: toRem(55px);
      height: toRem(55px);
      font-size: toRem(33px);
      margin-top: toRem(-55px);
      margin-bottom: toRem(15px);
    }
  }

  .default-title {
    color: var(--main-dark);

    @media (max-width: 960px) {
      font-size: toRem(32px);
    }

    @media (max-width: 768px) {
      // margin-bottom: toRem(-20px);
      margin-bottom: toRem(32px);
    }

    @media (max-width: 540px) {
      letter-spacing: toRem(-.8px);

      span {
        white-space: initial !important;
      }
    }

    @media (max-width: 420px) {
      font-size: toRem(32px);
    }

    @media (max-width: 380px) {
      font-size: toRem(28px);
    }
  }

  &__icon {
    color: var(--main-color);
    margin-bottom: toRem(-20px);

    @media (max-width: 768px) {
      margin: auto;
      text-align: center;
      width: max-content;
      margin-bottom: toRem(0px);
      order: -2;
    }

    & > svg {
      height: toRem(70px);
      width: auto;

      @media (max-width: 960px) and (min-width: 768px) {
        height: toRem(52px);
        margin-bottom: toRem(30px);
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr;
    gap: toRem(60px);
    align-items: center;

    @media (max-width: 960px) {
      grid-template-columns: 0.7fr 1.3fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__info {
    color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: toRem(40px);

    @media (max-width: 768px) {
      display: contents;
    }
  }

  &__image {
    position: relative;

    @media (max-width: 768px) {
      display: contents;
    }

    img {
      width: 100%;
      margin-bottom: toRem(20px);

      @media (max-width: 768px) {
        max-width: toRem(420px);
        // margin: toRem(-45px) auto;
        margin: 0 auto;
      }
    }
  }
  
  &__text {
    display: flex;
    flex-direction: column;
    gap: toRem(25px);
    font-size: toRem(23.596px);
    font-weight: 400;
    line-height: 160%;
    max-width: toRem(590px);
    margin-bottom: toRem(32px);

    @media (max-width: 960px) {
      font-size: toRem(18px);
    }

    b {
      font-weight: 800;
    }

    p {
      margin: 0;
    }
  }

  .default-button {
    width: 60%;
    margin: auto;
    display: block;
  }

  &:not(.--contrast) {
    background: transparent;
  }

  &.--contrast {
    .product-block__number {
      color: var(--main-color);
      background: rgba(255,255,255,.9);
    }
    .product-block__icon {
      color: rgba(255,255,255,.9);
    }
    .product-block__info {
      color: rgba(255,255,255,.9);
    }
    .default-title {
      color: rgba(255,255,255,1);
      span {
        background: rgba(255,255,255,1);
        color: var(--main-color);
      }
    }
    .default-button {
      box-shadow: toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(80, 208, 207, 0);
      &:hover {
        box-shadow: toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(80, 208, 207, 0.2);
      }
    }
    .product-block__advantage {
      &--title {
        color: var(--programs-contrast-color);
        span {
          background: var(--programs-contrast-color);
          color: var(--main-color);
        }
      }
      &--icon {
        background: var(--programs-contrast-color);
      }
    }
  }

  &__advantages {
    display: grid;
    grid-template-columns: auto;
    gap: toRem(24px) toRem(30px);

    @media (max-width: 768px) {
      margin-bottom: toRem(32px);
    }

    @media (max-width: 540px) {
      grid-template-columns: 1fr;
    }
  }

  &__advantage {
    display: flex;
    align-items: center;
    gap: toRem(20px);

    &.--big {
      grid-column: span 2;

      @media (max-width: 540px) {
        grid-column: 1;
      }
    }

    &--icon {
      width: toRem(80px);
      min-width: toRem(80px);
      height: toRem(80px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;

      @media (max-width: 960px) {
        width: toRem(60px);
        min-width: toRem(60px);
        height: toRem(60px);
      }

      & > svg {
        height: toRem(32px);
        width: toRem(32px);

        @media (max-width: 960px) {
          height: toRem(24px);
          width: toRem(24px);
        }
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        opacity: .3;
        box-shadow: 
          toRem(8px) toRem(8px) toRem(16px) toRem(0px) var(--shadow-down), 
          toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) var(--shadow-up),
          toRem(8px) toRem(8px) toRem(16px) toRem(0px) var(--shadow-down) inset, 
          toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) var(--shadow-up) inset;
      }
    }

    &--title {
      font-weight: 400;
      font-size: toRem(19px);
      color: var(--programs-color);
      span {
        background: var(--main-color);
        color: var(--programs-contrast-color);
        font-weight: 600;
        padding: 0 toRem(5px);
        margin: 0 toRem(-5px);
        border-radius: toRem(5px);
        margin-bottom: toRem(5px);
        display: inline-block;
      }

      @media (max-width: 960px) {
        font-size: toRem(16px);
      }
    }
  }

  .default-button {
    @media (max-width: 768px) {
      order: 4;
    }

    @media (max-width: 540px) {
      width: calc(100% + toRem(60px));
      border-radius: 0;
      margin: 0 toRem(-30px);
    }
  }
}