.video {
  height: 100vh;
  min-height: toRem(600px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color);
  position: relative;
  z-index: 2;

  video {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .3;
  }

  @media (max-width: 960px) {
    height: auto;
    min-height: 0;
    padding: toRem(60px) 0;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    // background: url('../assets/img/video-bg.jpg') center no-repeat;
    background-size: cover !important;
    opacity: .2;
  }

  &__container {
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 960px) {
      width: 100%;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--main-dark);
      border: 2px solid rgba(255,255,255,.3);
      border-radius: toRem(40px);
      opacity: .5;

      @media (max-width: 960px) {
        border-radius: toRem(20px);
      }
    }

    &--title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: toRem(45px) 0;
      text-align: center;
      color: var(--text-contrast);
      font-size: toRem(22px);
      font-weight: 700;
      opacity: .8;

      @media (max-width: 660px) {
        font-size: toRem(18px);
      }

      @media (max-width: 540px) {
        font-size: toRem(15px);
        padding: toRem(25px) 0;
      }

      @media (max-width: 390px) {
        font-size: toRem(12px);
      }
    }

    button {
      padding: 0;
      border: none;
      background: transparent;
      color: var(--text-contrast);
      z-index: 2;
      position: relative;
      opacity: .5;
      transition: .2s;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      & > svg {
        float: left;
        height: toRem(145px);
        width: auto;

        @media (max-width: 660px) {
          height: toRem(125px);
        }

        @media (max-width: 540px) {
          height: toRem(80px);
          translate: 0 -20%;
        }
  
        @media (max-width: 420px) {
          height: toRem(60px);
          font-size: toRem(12px);
        }
      }
    }
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1000;

  .video-modal__close {
    width: toRem(72px);
    height: toRem(72px);
    background: rgba(255,255,255,.7);
    border-radius: 50%;
    position: absolute;
    top: toRem(80px);
    right: toRem(80px);
    z-index: 20;
    cursor: pointer;
    padding: toRem(15px);
    color: #000;

    @media (max-height: 960px) {
      right: toRem(30px);
      top: toRem(30px);
      width: toRem(60px);
      height: toRem(60px);
    }
  }

  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}