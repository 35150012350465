.team-container {
  @media (max-width: 1195px) {
    border-radius: 0 !important;
  }

  .main-container {
    @media (max-width: 960px) {
      padding: 0;
    }
  }
}

.team {
  padding: toRem(50px) 0 toRem(0px);

  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: toRem(16px);
  }

  @media (max-width: 960px) {
    overflow-y: hidden !important;
    overflow-x: auto;
    padding: toRem(50px) toRem(15px) toRem(0px);
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    &--photo {
      width: 100%;
      border-radius: toRem(20px);
      border: toRem(8px) solid rgba(255,255,255,.2);
      overflow: hidden;

      @media (max-width: 960px) {
        width: toRem(190px);
      }

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        float: left;
      }
    }

    &--name {
      font-size: toRem(20px);
      color: var(--programs-contrast-color);
      font-weight: 700;
      padding: toRem(9px) 0;
    }

    &--desc {
      text-transform: uppercase;
      color: var(--programs-contrast-color);
      font-size: toRem(14px);
      opacity: .9;
      letter-spacing: toRem(2.359px);
    }
  }
}