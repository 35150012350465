.footer {
  padding: toRem(60px) 0 toRem(60px);
  background: url('../assets/img/footer.jpg') center no-repeat;
  background-size: cover;

  &__row {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @media (max-width: 768px) {
      gap: toRem(65px);
    }

    @media (max-width: 600px) {
      gap: toRem(45px);
    }

    @media (max-width: 540px) {
      grid-template-columns: 1fr;
    }

    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: toRem(90px);

      @media (max-width: 768px) {
        flex-direction: column;
        gap: toRem(40px);
      }
    }
  }

  &__menu-lg {
    flex: 1;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: toRem(36px);

      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
      }

      li {
        padding: 0;
        margin: 0;

        a {
          position: relative;
          font-size: toRem(18px);
          font-weight: 500;
          text-decoration: none;
          color: var(--programs-contrast-color);
          transition: .2s;
          cursor: pointer;
      
          @media (max-width: 960px) {
            font-size: toRem(15px);
          }
      
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--programs-contrast-color);
            scale: 0;
            transition: .2s;
          }
      
          &:hover {
            color: white;
            &:after {
              scale: 1;
            }
          }
        }
      }
    }
  }

  &__socials-lg {
    ul {
      display: flex;
      margin-left: auto;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        svg {
          float: left;
          width: toRem(48px);
          height: toRem(48px);
        }
      }
    }

  }

  &__menus {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    color: var(--programs-contrast-color);
    line-height: 160%;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: toRem(40px) toRem(40px);
      padding: 0 toRem(60px);
      margin: auto;
    }

    @media (max-width: 460px) {
      padding: 0 toRem(30px);
    }

    @media (max-width: 390px) {
      padding: 0 toRem(15px);
    }

    &--item {
      display: flex;
      flex-direction: column;
      gap: toRem(35px);
    }

    &--title {
      font-weight: 700;
      font-size: toRem(20px);

      @media (max-width: 460px) {
        margin-bottom: toRem(-15px);
      }

      @media (max-width: 340px) {
        font-size: toRem(18px);
      }
    }
  }

  &__menu {
    &--title {
      font-weight: 600;
      font-size: toRem(16px);
      margin-bottom: toRem(4px);

      @media (max-width: 340px) {
        font-size: toRem(14px);
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      
      & > li {
        & > a {
          font-size: toRem(14px);
          line-height: 160%;
          font-weight: 400;
          text-decoration: none;
          color: var(--programs-contrast-color);

          @media (max-width: 340px) {
            font-size: toRem(13px);
          }
        }
      }
    }
  }

  &__socials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      display: block;

      svg {
        height: toRem(48px);
        width: toRem(48px);

        @media (max-width: 540px) {
          height: toRem(70px);
          width: toRem(70px);
        }
      }
    }
  }

  &__side {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: toRem(31px);
    color: var(--programs-contrast-color);

    & > svg {
      height: toRem(43px);
      width: auto;

      @media (max-width: 540px) {
        height: toRem(62px);
      }
    }
  }
}

.footer-line {
  padding: toRem(20px) 0;
  display: flex;
  align-items: center;
  gap: toRem(30px);
  justify-content: center;
  background: #006261;
  color: rgba(235, 243, 255, 0.5);
  font-weight: 700;
  font-size: toRem(11px);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: toRem(5px);
    margin: toRem(-3px) 0 0;
  }

  a {
    color: #EBF3FF;
    text-decoration: none;
  }

  &__links {
    display: flex;
    gap: toRem(12px);
  }
}