.events {
  padding-bottom: toRem(120px);

  .secondary-container {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: toRem(40px);
  }

  &-list {
    margin-top: toRem(80px);

    @media (max-width: 768px) {
      margin-top: toRem(60px);
    }
  }

  .slick-track {
    overflow: visible !important;
  }

  .slick-list {
    overflow: visible !important;
    // background: red;
    padding: 80px 0 90px;
    margin: -80px 0 -90px;
  }
}

.event {
  padding: 0 toRem(15px);

  @media (max-width: 520px) {
    padding: 0 toRem(10px);
  }

  &:hover {
    .event__image {
      img {
        opacity: 1;
      }
    }
  }

  &__inset {
    border-radius: toRem(18px);
    background: #F0F0F8;
    padding: toRem(40px) toRem(100px) toRem(40px);
    box-shadow: 20px 20px 80px 0px rgba(180, 193, 213, 0.56), -20px -20px 50px 0px rgba(255, 255, 255, 0.11);

    @media (max-width: 1024px) {
      padding: toRem(30px) toRem(40px) toRem(30px);
    }

    @media (max-width: 520px) {
      padding: toRem(34px) toRem(35px) toRem(34px);
    }
  }

  &__image {
    margin-bottom: toRem(18px);
    background: #F0F0F8;
    border-radius: toRem(18px);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      aspect-ratio: 7/5;
      object-fit: cover;
      opacity: .7;
      transition: .2s;
    }

    // &:after {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: #149699;
    //   z-index: 2;
    //   opacity: .2;
    //   transition: .2s;
    // }
  }

  &__date {
    color: var(--main-dark);
    opacity: .7;
    margin-bottom: toRem(2px);
    font-size: 20.433px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    @media (max-width: 1024px) {
      font-size: toRem(18px);
    }

    @media (max-width: 520px) {
      font-size: toRem(17px);
    }
  }

  &__title {
    color: #005352;
    font-size: toRem(35px);
    font-weight: 700;
    line-height: 120%;
    margin-bottom: toRem(24px);

    @media (max-width: 1024px) {
      font-size: toRem(27px);
    }

    @media (max-width: 390px) {
      font-size: toRem(24px);
    }

    span {
      display: inline-flex;
      background: var(--main-color);
      padding: 0 toRem(5px);
      margin: 0 toRem(-5px);
      border-radius: toRem(5px);
      color: var(--text-contrast)
    }
  }

  &__text {
    margin-bottom: toRem(18px);
    line-height: 160%;
    font-weight: 400;
    font-size: toRem(20px);
    opacity: .7;
    color: var(--main-dark);

    @media (max-width: 1024px) {
      font-size: toRem(17px);
    }
  }

  &__place {
    display: flex;
    align-items: center;
    gap: toRem(25px);

    @media (max-width: 520px) {
      gap: toRem(20px);
    }

    &--icon {
      width: toRem(80px);
      min-width: toRem(80px);
      height: toRem(80px);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      @media (max-width: 520px) {
        width: toRem(68px);
        min-width: toRem(68px);
        height: toRem(68px);
      }

      @media (max-width: 520px) {
        width: toRem(48px);
        min-width: toRem(48px);
        height: toRem(48px);
      }

      & > svg {
        height: toRem(36px);
        width: toRem(36px);
        path {
          fill: white;
        }

        @media (max-width: 520px) {
          height: toRem(34px);
          width: toRem(34px);
        }

        @media (max-width: 520px) {
          height: toRem(20px);
          width: toRem(20px);
        }
      }
      
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(270deg, #50D0CF 0%, #20ADAC 100%);
        top: 0;
        left: 0;
        border-radius: 50%;
        opacity: 1;
        z-index: -1;
        box-shadow: 
          toRem(8px) toRem(8px) toRem(16px) toRem(0px) var(--shadow-down), 
          toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) var(--shadow-up);
      }
    }

    &--address {
      font-weight: 400;
      font-size: toRem(19px);
      color: var(--programs-color);

      @media (max-width: 1024px) {
        font-size: toRem(16px);
      }

      @media (max-width: 390px) {
        font-size: toRem(14px);
      }

      span {
        background: var(--main-color);
        color: var(--programs-contrast-color);
        font-weight: 600;
        padding: 0 toRem(5px);
        margin: 0 toRem(-5px);
        border-radius: toRem(5px);
        margin-bottom: toRem(5px);
        display: inline-block;
      }
    }
  }

  &__button {
    margin-top: toRem(35px);
  }
}