* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
  outline: none;
}

html, body {
  font-size: $default-font-size;
  background: var(--background-color);
  max-width: 100vw;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  background: rgba(240, 240, 248, 1);

  @media (max-width: 1300px) {
    font-size: 9px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 320px) {
    font-size: 13px;
  }
}

html {
  touch-action: manipulation;
  font-feature-settings: "rlig" 1,"calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-width: 100vw;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "SF Pro Display", -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  height: 100vh;
  width: 100vw;
}

.main-container {
  max-width: toRem(1280px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 toRem(15px);
}

.secondary-container {
  max-width: toRem(1350px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 toRem(15px);
}

.default-button {
  padding: toRem(11px) toRem(5px) toRem(9px);
  min-width: toRem(225px);
  font-size: toRem(18px);
  font-weight: 500;
  line-height: 163.78%;
  text-decoration: none !important;
  border: none;
  background: linear-gradient(270deg, var(--gradient-end) 0%, var(--gradient-start) 100%);
  color: var(--text-contrast);
  border-radius: toRem(10px);
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 
    toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(80, 208, 207, 0),
    toRem(4px) toRem(4px) toRem(20px) toRem(0px) var(--shadow-down), 
    toRem(-5px) toRem(-5px) toRem(20px) toRem(0px) var(--shadow-up), 
    toRem(2px) toRem(2px) toRem(4px) toRem(0px) var(--shadow-down);
  transition: .2s;

  font-family: 'Montserrat', sans-serif;

  &:hover {
    box-shadow: 
      toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(80, 208, 207, 0.4),
      toRem(4px) toRem(4px) toRem(20px) toRem(0px) var(--shadow-down), 
      toRem(-5px) toRem(-5px) toRem(20px) toRem(0px) var(--shadow-up), 
      toRem(2px) toRem(2px) toRem(4px) toRem(0px) var(--shadow-down);
  }

  @media (max-width: 960px) {
    font-size: 15px;
    min-width: toRem(180px);
    border-radius: toRem(7px);
  }

  &.--transparent {
    min-width: 0;
    background: transparent;
    color: var(--main-dark);
    box-shadow: none !important;
  }

  &.--link {
    text-decoration: underline 1px solid var(--main-dark) !important;
    text-underline-offset: toRem(2px);
    box-shadow: none !important;
  }

  &.--lg {
    padding: toRem(20px) toRem(5px) toRem(18px);

    @media (max-width: 960px) {
      min-width: toRem(220px);
    }
  }
}

.section {
  padding: toRem(80px) 0;
  background: url('../assets/img/bg-light.jpg') center no-repeat;
  background-size: cover;

  &.--contrast {
    background: url('../assets/img/bg-contrast.jpg') center no-repeat;
    background-size: cover;
  }

  &.--lg-p {
    padding: toRem(100px) 0;
  }

  &.--last {
    padding-bottom: toRem(480px);
  }

  &.--round {
    border-radius: toRem(30px);
  }

  &.--shadow {
    box-shadow: 
      toRem(15px) toRem(15px) toRem(60px) toRem(0px) var(--shadow-down), 
      toRem(-15px) toRem(-15px) toRem(60px) toRem(0px) var(--shadow-up), 
  }
}

.default-title {
  color: var(--text-color);
  font-weight: 700;
  text-align: center;
  font-size: toRem(44.953px);

  @media (max-width: 960px) {
    font-size: toRem(32px);
  }

  @media (max-width: 540px) {
    font-size: toRem(26px);
  }

  @media (max-width: 390px) {
    font-size: toRem(20px);
  }

  span {
    background: var(--main-color);
    color: var(--text-contrast);
    display: inline-flex;
    padding: 0 toRem(20px) toRem(5.5px);
    border-radius: toRem(5px);
    line-height: 90%;
    white-space: nowrap;

    @media (max-width: 960px) {
      padding: toRem(2.5px) toRem(10px) toRem(4.5px);
    }
  }

  &.--md {
    font-size: toRem(52.612px);
  }

  &.--lg {
    font-size: toRem(66.24px);

    @media (max-width: 960px) {
      font-size: toRem(42px);
    }
  
    @media (max-width: 540px) {
      font-size: toRem(35px);
    }
  
    @media (max-width: 390px) {
      font-size: toRem(30px);
    }
  
    @media (max-width: 340px) {
      font-size: toRem(26px);
    }
  }

  &.--contrast {
    color: var(--text-contrast);
    span {
      color: var(--main-color);
      background: var(--text-contrast);
    }
  }

  &__shield {
    margin-bottom: toRem(30px);

    @media (max-width: 960px) {
      margin-bottom: toRem(20px);
    }

    svg {
      height: toRem(110px);
      width: auto;

      @media (max-width: 960px) {
        height: toRem(70px);
        opacity: .7;
      }
    }
  }

  &.--left {
    text-align: left !important;
  }
}

.default-subtitle {
  color: var(--text-color);
  font-weight: 700;
  text-align: center;
  font-size: toRem(20px);
  line-height: 180%;
  margin-top: toRem(10px);

  &.--contrast {
    opacity: .8;
    color: var(--text-contrast);
  }
}

.section-sticky {
  position: relative;

  .section {
    position: sticky;
    top: 0;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.arrow-down {
  padding: toRem(90px) 0;
  text-align: center;
}

.section-skip {
  position: fixed;
  bottom: toRem(30px);
  right: toRem(30px);
  z-index: 6;
  background: var(--text-contrast);
  font-family: 'Montserrat', sans-serif;
  color: var(--main-color);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: toRem(6px);
  padding: toRem(8px) toRem(12px);
  border-radius: toRem(5px);
  font-size: 16px;
  cursor: pointer;

  & > svg {
    width: toRem(16px);
    path {
      fill: var(--main-color);
    }
  }
}

.w-100 {
  width: 100% !important;
}

.default-input {
  border-radius: toRem(10px);
  background: #F0F0F8;
  box-shadow:
    toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(225, 255, 255, 0),
    toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0),
    toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0),

    toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0.31) inset,
    toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0.43) inset;
  font-family: 'Montserrat', sans-serif;
  padding: toRem(12px) toRem(26px);
  color: #8F8F90;
  font-size: toRem(16px);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  border: none !important;
  transition: .2s;
  height: toRem(66px);

  @media (max-width: 520px) {
    padding: toRem(8px) toRem(20px);
    font-size: toRem(14px);
    height: toRem(52px);
  }

  &:-webkit-autofill,
  &:-webkit-autofill-strong-password,
  &:-webkit-autofill-strong-password-viewable,
  &:-webkit-autofill-strong-and-obscured {
    background: #EEFBFB !important;
  }

  &[type=email],
  &[type=password],
  &[type=text] {
    &:not(:placeholder-shown) {
      box-shadow:
        toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(20, 150, 153, 0.15),
        toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
        toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.9),

        toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0) inset,
        toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0) inset;
      background: #F7FCFC;
      color: #006261;
      &:focus {
        box-shadow:
          toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(20, 150, 153, 0.15),
          toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
          toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.9),

          toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0) inset,
          toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0) inset;
        background: #F7FCFC;
        color: #006261;
      }
    }
  }

  &.--toggleable-password {
    padding-right: toRem(70px);
  }

  &:focus {
    color: #006261;
    background: #F0F0F8;
    box-shadow:
      toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(225, 255, 255, 0),
      toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
      toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.9),

      toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0) inset,
      toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0) inset;
  }

  &.--success {
    box-shadow:
      toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(20, 150, 153, 0.15),
      toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
      toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.9),

      toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0) inset,
      toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0) inset;
    background: #F7FCFC;
    color: #006261;
  }

  &.--error {
    box-shadow:
      toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(225, 68, 87, 0.15),
      toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
      toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.9),

      toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0) inset,
      toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0) inset !important;
    background: #FFEDF3 !important;
    color: #E14457 !important;
  }

  &::placeholder {
    color: #8F8F90;
    font-weight: 300;
  }
}

textarea.default-input {
  line-height: 140%;
  min-height: toRem(115px);
}

.input--error {
  position: absolute;
  right: 0;
  bottom: 0;
  translate: 0 140%;

  color: #E14457;

  font-size: toRem(14px);
  font-style: normal;
  font-weight: 400;
}

.input--holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__bottom {
    background: linear-gradient(270deg, rgba(80, 208, 208, 0.05) 0%, rgba(32, 173, 173, 0.05) 100%);
    padding: toRem(15px) toRem(15px)toRem(9px);
    margin-top: toRem(-6px);
    border-radius: 0 0 toRem(15px) toRem(15px);

    color: #7BA5A5;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: toRem(16px);
    font-weight: 500;
    line-height: 142.623%;
  }

  label {
    display: block;
    width: 100%;
    color: #149699;
    font-size: toRem(16px);
    font-weight: 500;
    margin-bottom: toRem(10px);
    position: relative;
    z-index: 2;

    span {
      color: #C0A161;
      font-weight: 500;
    }

    &.--red {
      color: #E14457;
    }

    a {
      color: #149699;
    }
  }

  input {
    display: block;
    width: 100%;
    &.--has-currency {
      padding-right: toRem(70px);
    }
  }

  i {
    display: none;
  }

  &.--icon {
    input {
      padding-right: toRem(73px);
    }
    i {
      position: absolute;
      right: 0;
      top: 50%;
      translate: 0 -50%;
      z-index: 2;
      display: block;
      padding: 0 toRem(30px);

      @media (max-width: 1024px) {
        padding: 0 toRem(18px);
      }

      & > svg {
        width: toRem(23px);
        height: toRem(24px);
        float: left;
      }
    }
  }

  &.--referral {
    .default-button,
    .default-input {
      height: toRem(51px);
    }
    .default-button {
      font-size: toRem(14px);
      width: auto;
      @media (max-width: 390px) {
        width: 100%;
      }
    }
  }
}

.custom-selector {
  position: relative;
  &__selected {
    border-radius: 10px;
    background: #F0F0F8;
    box-shadow:
      toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(225, 255, 255, 0),
      toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0),
      toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0),

      toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0.31) inset,
      toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0.43) inset;
    font-family: 'Montserrat', sans-serif;
    padding: toRem(12px) toRem(26px);
    color: #8F8F90;
    font-size: toRem(16px);
    font-style: normal;
    font-weight: 300;
    line-height: 260%;
    border: none !important;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.--has-value {
      font-family: 'Roboto', sans-serif;
      font-size: toRem(16px);
      font-weight: 500;

      box-shadow:
        toRem(0px) toRem(0px) toRem(0px) toRem(2px) rgba(20, 150, 153, 0.15),
        toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
        toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.9),

        toRem(8px) toRem(8px) toRem(16px) toRem(0px) rgba(180, 193, 213, 0) inset,
        toRem(-8px) toRem(-8px) toRem(16px) toRem(0px) rgba(255, 255, 255, 0) inset;
      background: #F7FCFC;
      color: #006261;

      @media (max-width: 520px) {
        font-size: toRem(14px);
      }
    }

    @media (max-width: 520px) {
      padding: toRem(8px) toRem(20px);
      font-size: toRem(14px);
    }

    svg {
      width: toRem(10px);
      height: auto;
      transform: rotate(-90deg);
    }
  }

  &__scrollbar {
    height: auto;

  }

  &__list {
    position: absolute;
    z-index: 20;
    margin: 0;
    padding: 0 0;
    top: toRem(-1px);
    left: toRem(-13px);
    width: calc(100% + toRem(26px));
    min-width: toRem(320px);
    height: auto;
    border-radius: 10px;
    list-style: none;
    overflow: auto;
    max-height: toRem(325px);
    box-shadow:
      toRem(15px) toRem(15px) toRem(66px) toRem(0px) rgba(180, 193, 213, 0.47),
      toRem(-15px) toRem(-15px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.00);

    border-radius: toRem(10px);
    border: toRem(1px) solid rgba(20, 150, 153, 0.18);
    background: #E8EEF6;
    cursor: pointer;

    li {
      width: 100%;
      padding: toRem(12px) toRem(38px);
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      height: toRem(65px);

      @media (max-width: 520px) {
        padding: toRem(12px) toRem(25px);
        height: auto;
      }

      .--info {
        display: flex;
        gap: toRem(40px);
        align-items: center;
        @media (max-width: 520px) {
          flex-direction: column;
          gap: 0;
          align-items: flex-start;
        }
      }

      @media (max-width: 520px) {
        gap: 0 toRem(40px);
      }

      @media (max-width: 340px) {
        gap: 0 toRem(20px);
      }

      .--title {
        font-weight: 300;
        font-family: 'Montserrat', sans-serif;
        font-size: toRem(16px);
        line-height: 260%;
        color: #8F8F90;
        grid-area: title;

        @media (max-width: 520px) {
          line-height: 140%;
          font-size: toRem(14px);
        }

        @media (max-width: 390px) {
          max-width: toRem(130px);
        }
      }

      .--description {
        font-size: toRem(14px);
        color: #C0A161;
        grid-area: description;

        @media (max-width: 520px) {
          font-size: toRem(12px);
        }
      }

      &.--this {
        .--title {
          color: #006261;
          font-weight: 500;
        }

        span {
          font-weight: 300;
        }
      }

      &:hover {
        background: rgba(20, 150, 153, 0.05);
      }

      .default-button {
        font-size: toRem(13px);
        padding: toRem(9.5px) toRem(16px);
        line-height: 100%;
        border-radius: toRem(5px);
        margin-left: auto;
        height: auto;
        max-width: toRem(120px);
        min-width: 0;

        @media (max-width: 540px) {
          display: none;
        }
      }

      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          width: calc(100% - toRem(76px));
          left: toRem(38px);
          bottom: 0;
          height: 1px;
          opacity: 0.1;
          background: #20ADAC;
        }
      }
    }
  }

  &__this {
    margin-left: auto;
    color: #A3C2C1;
    font-family: 'Montserrat', sans-serif;
    font-size: toRem(12px);
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-align: right;
  }
}

.input--counter {
  position: absolute;
  bottom: toRem(14px);
  right: toRem(20px);
  color: #006261;
  font-size: toRem(16px);
  font-weight: 400;
  line-height: 140%;
  opacity: .5;
}

.skew-this {
  transition: .1s transform;
}

.--animated-swipe-preview {
  animation: animatedSwipePreview 2s ease-in-out 0s infinite;
}

@keyframes animatedSwipePreview {
  0% { translate: 0% 0%}
  20% { translate: toRem(-30px) 0%}
  30% { translate: toRem(-30px) 0%}
  50% { translate: 0% 0%}
  100% { translate: 0% 0%}
}

.Toastify {
  &__toast-container--top-right {
    padding: 0 !important;
    right: 0 !important;
    top: toRem(80px) !important;
    width: 100% !important;
    max-width: toRem(410px) !important;
    z-index: 100000;

    @media (max-width: 480px) {
      top: toRem(15px) !important;
      border-radius: 0 !important;
      margin-bottom: toRem(5px);
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  &__toast {
    min-height: toRem(65px);
    border-radius: toRem(20px) 0 0 toRem(20px) !important;
    box-shadow:
      toRem(13px) toRem(13px) toRem(30px) toRem(0px) rgba(180, 193, 213, 0.32),
      toRem(-15.88962px) toRem(-15.88962px) toRem(40px) toRem(0px) rgba(255, 255, 255, 0.48) !important;

    &--error {
      border: 2px solid rgba(225, 68, 87, 0.15) !important;
      // border-right: 0 !important;
      background: #FFEDF3 !important;
      color: #E14457 !important;

      .Toastify__toast-body {
        font-weight: 500 !important;
        &:before {
          content: '';
          width: toRem(28px);
          height: toRem(28px);
          background: url('../assets/img/toasts/error.png') center no-repeat;
          background-size: contain;
        }
      }
    }

    &--success {
      border: 2px solid rgba(20, 150, 153, 0.15) !important;
      // border-right: 0 !important;
      background: #EEFBFB !important;
      color: #006261 !important;
      
      .Toastify__toast-body {
        font-weight: 500 !important;
        &:before {
          content: '';
          width: toRem(28px);
          height: toRem(28px);
          background: url('../assets/img/toasts/success.png') center no-repeat;
          background-size: contain;
        }
      }
    }

    &-body {
      font-family: 'Montserrat', sans-serif;
      font-size: toRem(13px);
      font-weight: 350 !important;
      line-height: 120%;
      padding: toRem(6px) toRem(40px);
      display: flex;
      align-items: center;
      gap: toRem(10px);

      @media (max-width: 480px) {
        padding: toRem(6px) toRem(10px);
      }
    }

    @media (max-width: 480px) {
      // border-radius: 0 !important;
      border-radius: toRem(15px) !important;
      margin-bottom: toRem(5px);
      width: 90%;
      left: 5%;
    }
  }

  &__close-button {
    display: none !important;
  }
}

.language-selector {
  position: relative;
  font-family: 'Montserrat', sans-serif;

  &__close {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top: toRem(40px);
    right: toRem(40px);
    cursor: pointer;
    z-index: 2;

    svg {
      float: left;
      width: toRem(52px);
      height: auto;

      @media (max-width: 768px) {
        width: toRem(30px);
      }
    }
  }

  &__current {
    font-weight: 800;
    display: flex;
    text-transform: uppercase;
    color: #CFB37B;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    & > svg {
      height: toRem(8px);
      width: auto;
    }
  }

  &__list {
    position: fixed;
    background: rgba(240, 240, 248, 0.8);
    flex-direction: column;
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    top: 0;
    width: 100%;
    z-index: 999;

    &--inset {
      height: 90%;
      width: 100%;
      opacity: 1;
      background: #F0F0F8;
      box-shadow: 0px 0px toRem(66px) 0px rgba(180, 193, 213, 0.47), 0px 0px toRem(40px) 0px rgba(255, 255, 255, 0.48);
      position: relative;
      border-radius: 50px;
      margin: auto;
      width: 100%;
      max-width: toRem(1198px);
      display: flex;
      flex-direction: column;

      @media (max-width: 1024px) {
        padding: 0 toRem(15px);
      }

      &__holder {
        margin: auto;
        padding: 40px 0;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    
        @media (max-width: 1024px) {
          height: auto;
        }
      }
    }

    &--title {
      margin-bottom: toRem(50px);
      display: block;
  
      color: #8F8F90;
  
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: toRem(26.35px);
      font-weight: 500;
      line-height: 140%;
  
      @media (max-width: 1024px) {
        font-size: toRem(18px);
        margin-bottom: toRem(20px);
      }
  
      @media (max-width: 390px) {
        font-size: toRem(14px);
        margin-bottom: toRem(20px);
      }
    }
  }

  &__buttons {
    width: 100%;
    max-width: toRem(900px);

    &--item {
      color: rgba(20, 151, 153, 0.8);
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: toRem(55.502px);
      font-weight: 700;
      line-height: 118.243%;
      padding: toRem(28px) 0;
      border: none;
      background: transparent;
      border-bottom: 1px solid rgba(20, 151, 153, 0.2);
      cursor: pointer;
      display: block;
      width: 100%;
      position: relative;

      transition: .2s color;

      @media (max-width: 1024px) {
        font-size: toRem(42px);
        padding: toRem(18px) toRem(15px);
      }

      @media (max-width: 768px) {
        font-size: toRem(26px);
      }

      @media (max-width: 390px) {
        font-size: toRem(19px);
      }

      @media (max-width: 300px) {
        font-size: toRem(17px);
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        color: #149699;

        span {
          opacity: 1;
          translate: 0% -50%;
        }
      }

      span {
        border-radius: 10px;
        background: linear-gradient(268deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 52.74%), linear-gradient(270deg, #50D0CF 0%, #20ADAC 100%);
        box-shadow: 4px 4px 20px 0px rgba(111, 140, 176, 0.40), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.19);
        padding: toRem(6px) toRem(20px);
        position: absolute;
        right: 0;
        top: 50%;
        translate: -20% -50%;
        opacity: 0;
        transition: .2s;

        @media (max-width: 768px) {
          padding: 0 toRem(8px);
        }

        i {
          color: #ffffff;
          font-family: 'Montserrat', sans-serif;
          font-size: toRem(30px);
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          translate: 0 toRem(-2px);
          float: left;

          @media (max-width: 768px) {
            font-size: toRem(24px);
          }

          @media (max-width: 390px) {
            display: none;
          }
        }
      }
    }
  }
}