.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  
  &-link {
    position: relative;
    font-size: toRem(18px);
    font-weight: 500;
    text-decoration: none;
    color: var(--main-dark);
    transition: .2s;

    @media (max-width: 960px) {
      font-size: toRem(15px);
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--main-color);
      scale: 0;
      transition: .2s;
    }

    &:hover {
      color: var(--main-color);
      &:after {
        scale: 1;
      }
    }
  }

  &-toggle {
    position: absolute;
    left: 0;
    top: 50%;
    translate: 0 -50%;
    border: none;
    background: linear-gradient(90deg, var(--gradient-start), var(--gradient-end));
    padding: toRem(10px);
    border-radius: 0 toRem(7px) toRem(7px) 0;
    display: none;

    @media (max-width: 960px) {
      display: block;
    }

    svg {
      float: left;
      height: toRem(22px);
      width: toRem(22px);
    }
  }

  &:before {
    opacity: 0;
    background: var(--background-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1;
    transition: .2s;
  }
  
  &.--fixed {
    // backdrop-filter: blur(15px);
    // -webkit-backdrop-filter: blur(15px);
    &:before {
      opacity: .9;
    }
    .menu__holder {
      padding: toRem(30px) 0;

      @media (max-width: 960px) {
        padding: toRem(15px) toRem(0px) toRem(15px) toRem(49px);
        gap: toRem(20px);
      }
    }
  }

  &__holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: toRem(40px);
    padding: toRem(50px) 0;
    transition: .2s;

    @media (max-width: 1024px) {
      gap: toRem(50px);
    }

    @media (max-width: 960px) {
      padding: toRem(20px) toRem(0px) toRem(20px) toRem(49px);
      gap: toRem(20px);
    }
  }
  
  &__logo {
    color: var(--main-color);
    & > svg,
    & > img {
      height: toRem(46px);
      width: auto;
      float: left;

      @media (max-width: 1024px) {
        height: toRem(42px);
      }
    }

    @media (max-width: 960px) {
      display: none;
    }

    &--mobile {
      display: none;
      color: var(--main-color);
      & > svg,
      & > img {
        height: toRem(42px);
        width: auto;
        float: left;

        @media (max-width: 460px) {
          height: toRem(32px);
        }
      }

      @media (max-width: 960px) {
        display: block;
      }
    }
  }

  &__nav {
    flex: 1;

    @media (max-width: 960px) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: toRem(30px);
      list-style: none;
      margin: 0;
      padding: 0;

      @media (max-width: 1024px) {
        gap: toRem(20px);
      }

      li {
        padding: 0;
        margin: 0;

        a {
          color: var(--main-dark);
          text-decoration: none;
          font-size: toRem(17px);
          font-weight: 700;
          cursor: pointer;
          transition: .2s;
          position: relative;

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--main-color);
            scale: 0;
            transition: .2s;
          }

          &:hover {
            color: var(--main-color);
            &:after {
              scale: 1;
            }
          }

          @media (max-width: 1024px) {
            font-size: toRem(16px);
          }
        }
      }
    }
  }
  
  &__right {
    display: flex;
    align-items: center;
    gap: toRem(20px);
  }

  @media (max-width: 460px) {
    .default-button {
      min-width: 0;
      padding: toRem(10px) toRem(25px);
      font-size: toRem(14px);

      &.--transparent {
        padding: toRem(10px) toRem(5px);
      }
    }
  }

  @media (max-width: 390px) {
    .default-button {
      min-width: 0;
      padding: toRem(6px) toRem(15px);
      font-size: toRem(12px);

      &.--transparent {
        padding: toRem(6px) toRem(5px);
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(240, 240, 248, 1);
  z-index: 11;
  display: flex;
  flex-direction: column;

  .menu-toggle {
    top: 20px;
    translate: 0 0;

    @media (max-width: 390px) {
      top: 15px;
    }
  }

  &__top {
    padding: toRem(20px) toRem(0px) toRem(15px) toRem(64px);

    @media (max-width: 460px) {
      padding: toRem(24px) toRem(0px) toRem(15px) toRem(64px);
    }

    @media (max-width: 390px) {
      padding: toRem(20px) toRem(0px) toRem(15px) toRem(64px);
    }
  }

  &__logo {
    color: var(--main-color);
    & > svg,
    & > img {
      height: toRem(43px);
      width: auto;

      @media (max-width: 460px) {
        height: toRem(33px);
      }
    }
  }

  &__close {
    position: absolute;
    top: toRem(20px);
    right: toRem(20px);
    border: none;
    background: transparent;

    & > svg {
      float: left;
      height: toRem(43px);

      @media (max-width: 390px) {
        height: toRem(33px);
      }
    }
  }

  &__body {
    padding: toRem(20px);
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      gap: toRem(12px);
      display: flex;
      flex-direction: column;
      margin-bottom: toRem(40px);
      
      li {
        a {
          display: flex;
          align-items: center;
          gap: toRem(12px);

          color: #004141;

          transition: .2s;

          font-family: 'Montserrat', sans-serif;
          font-size: toRem(16px);
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          text-decoration: none;

          border-radius: toRem(10px);
          background: #F0F0F8;
          box-shadow:
            toRem(6px) toRem(6px) toRem(25px) toRem(0px) rgba(180, 193, 213, 0.50),
            toRem(-6px) toRem(-6px) toRem(15px) toRem(0px) rgba(255, 255, 255, 0.44);

          span {
            width: toRem(48px);
            height: toRem(48px);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: toRem(6px);
            background: #F0F0F8;
            box-shadow:
              toRem(6px) toRem(6px) toRem(25px) toRem(0px) rgba(180, 193, 213, 0.5),
              toRem(-6px) toRem(-6px) toRem(15px) toRem(0px) rgba(255, 255, 255, 0.5);
            border-radius: toRem(10px);

            transition: .2s;

            i {
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
              background: #F0F0F8;
              color: #D0B47D;
              box-shadow:
                toRem(6px) toRem(6px) toRem(25px) toRem(0px) rgba(180, 193, 213, 0.5),
                toRem(-6px) toRem(-6px) toRem(15px) toRem(0px) rgba(255, 255, 255, 0.5);
              border-radius: toRem(10px);

              img,
              svg {
                height: toRem(17.5px);
                max-width: toRem(17.5px);
                width: auto;
              }

              transition: .2s;
            }
          }
        }
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: toRem(30px) 0;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      gap: toRem(1px);
      display: flex;
      justify-content: center;

      li {
        svg {
          path {
            fill: var(--main-color);
            height: toRem(47px)
          }
        }
      }
    }
  }
}