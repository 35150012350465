.geography-container {
  padding: toRem(80px) 0 toRem(20px);
}

.geography {
  position: relative;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 22/13;
  }
  
  &__point {
    position: absolute;
    display: flex;
    align-items: center;
    gap: toRem(15px);
    white-space: nowrap;
    color: var(--programs-color);
    transition: .2s;
    cursor: pointer;
    aspect-ratio: 22/13;

    &.--right {
      flex-direction: row-reverse;
      translate: -85% 0;

      .geography__point--title,
      .geography__point--desc {
        text-align: right;
      }
    }

    &:hover {
      color: var(--main-dark);

      & > svg {
        path {
          fill: var(--main-color)
        }
      }
    }

    & > svg {
      height: toRem(38px);
      width: auto;
      path {
        transition: .2s;
      }
    }

    &--title {
      font-size: toRem(20px);
      font-weight: 600;
      margin-bottom: toRem(4px);
    }

    &--side {
      translate: 0 40%;
    }
  }
}

.geography-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: toRem(17px);
  margin: toRem(100px) auto toRem(80px);
  width: max-content;

  @media (max-width: 1200px) {
    gap: toRem(30px);
  }

  @media (max-width: 990px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: toRem(60px) toRem(30px);
  }

  @media (max-width: 960px) {
    .slick-list {
      padding-top: toRem(240px) !important;
      margin-top: toRem(-240px) !important;
    }
    .slick-track {
      display: flex !important;
      align-items: stretch;
    }
    .slick-slide {
      padding: toRem(8px);
      min-width: toRem(300px);
      height: auto;

      & > div {
        height: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    gap: toRem(60px) toRem(30px);
  }
}

.geography-block {
  border-radius: toRem(100px);
  background: #F0F0F8;
  box-shadow:
    toRem(10px) toRem(10px) toRem(20px) toRem(0px) var(--shadow-down) inset, 
    toRem(-10px) toRem(-10px) toRem(20px) toRem(0px) var(--shadow-up) inset;
  text-align: center;
  padding: 0 toRem(60px) toRem(60px);
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: toRem(315px);

  @media (max-width: 1200px) {
    padding: 0 toRem(45px) toRem(45px);
    border-radius: toRem(60px);
  }

  @media (max-width: 960px) {
    padding: 0 toRem(35px) toRem(35px);
    border-radius: toRem(40px);
    height: 100%;
  }

  @media (max-width: 340px) {
    width: toRem(290px);
  }

  &__icon {
    width: toRem(192px);
    height: toRem(192px);
    background: var(--background-color);
    border-radius: 50%;
    position: relative;
    box-shadow:
      toRem(10px) toRem(10px) toRem(20px) toRem(0px) var(--shadow-down), 
      toRem(-10px) toRem(-10px) toRem(20px) toRem(0px) var(--shadow-up);
    margin: -45px auto 20px;

    @media (max-width: 960px) {
      width: toRem(162px);
      height: toRem(162px);
    }
    
    &--inset {
      width: toRem(140px);
      height: toRem(140px);
      background: var(--background-color);
      box-shadow:
        toRem(20px) toRem(20px) toRem(50px) toRem(0px) var(--shadow-down), 
        toRem(-20px) toRem(-20px) toRem(50px) toRem(0px) var(--shadow-up);
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @media (max-width: 960px) {
        width: toRem(120px);
        height: toRem(120px);
      }

      & > img,
      & > svg {
        height: toRem(60px);
        width: toRem(60px);
        object-fit: contain;
      }
    }
  }

  &__sub-title {
    color: var(--main-color);
    margin-bottom: toRem(2px);
    font-size: toRem(16px);
    font-weight: 700;
  }

  &__title {
    text-transform: uppercase;
    color: var(--geography-title);
    font-size: toRem(29px);
    font-weight: 700;
    margin-bottom: toRem(2px);
  }

  &__country {
    opacity: .5;
    text-transform: uppercase;
    color: var(--geography-title);
    font-weight: 700;
    font-size: toRem(16px);

    &:after {
      content: '';
      display: block;
      width: toRem(64px);
      height: toRem(3px);
      border-radius: toRem(5px);
      background: var(--main-color);
      opacity: .3;
      margin: toRem(14px) auto toRem(23px);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: toRem(30px);
  }

  &__info {
    display: flex;
    margin: auto;
    width: 100%;
    text-align: left;
    gap: toRem(12px);
    font-size: toRem(12px);
    font-weight: 700;
    color: var(--geography-title);

    div {
      opacity: .7;
    }

    span {
      translate: 0 0%;
      svg {
        height: toRem(26px);
        width: toRem(26px);
      
        path {
          fill: var(--main-color);
        }
      }
    }
  }

  &__soon {
    margin: auto;
    translate: 0 -25%;
    color: var(--main-color);
    font-weight: 700;
    font-size: toRem(30px);
  }
}