.contact {
  padding: toRem(125px) 0 toRem(70px);
}

.contact-form {
  max-width: toRem(1195px);
  display: grid;
  grid-template-columns: 1.05fr 1.95fr;
  box-shadow: 
    toRem(15px) toRem(15px) toRem(60px) toRem(0px) var(--shadow-down), 
    toRem(-15px) toRem(-15px) toRem(60px) toRem(0px) var(--shadow-up);
  border-radius: toRem(40px);
  overflow: hidden;
  margin-top: toRem(65px);

  @media (max-width: 960px) {
    grid-template-columns: 1.2fr 1.8fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }

  &__info {
    padding: toRem(50px) toRem(55px) toRem(50px) toRem(45px);
    background: var(--main-color);
    border-radius: toRem(40px);
    color: var(--text-contrast);
    position: relative;
    z-index: 2;

    & > svg {
      color: white;
      opacity: .12;
      width: toRem(150px);
      height: auto;
      z-index: -1;
      position: absolute;
      bottom: toRem(20px);
      right: toRem(20px);
    }

    &--title {
      font-size: toRem(20px);
      margin-bottom: toRem(9px);
      font-weight: 700;
      line-height: 160%;
    }

    &--text {
      font-size: toRem(14px);
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin-bottom: toRem(45px);
    }

    &--list {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: toRem(35px);

      li {
        display: flex;
        gap: toRem(16px);
        font-size: toRem(14px);
        letter-spacing: toRem(2.66px);
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;

        svg {
          translate: 0 -15%;
          width: toRem(24px);
          height: toRem(24px);
        }
      }
    }
  }

  &__inputs {
    padding: toRem(45px) toRem(100px);
    max-width: toRem(700px);
    margin: auto;
    width: 100%;

    @media (max-width: 960px) {
      padding: toRem(35px) toRem(40px);
    }

    &--grid {
      display: grid;
      gap: toRem(30px) toRem(15px);
      grid-template: 
        "name email"
        "subject subject"
        "message message";
      margin-bottom: toRem(25px);

      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        gap: toRem(12px);
      }

      @media (max-width: 660px) {
        gap: toRem(12px);
      }
    }

    .--name {
      grid-area: name;
    }

    .--email {
      grid-area: email;
    }

    .--subject {
      grid-area: subject;
    }

    .--message {
      grid-area: message;
    }

    .default-button {
      width: 100%;
      padding: toRem(12px) toRem(20px);
      line-height: 163%;
    }
  }
}