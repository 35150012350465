.main {
  width: 100%;
  height: 100vh;
  min-height: toRem(600px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: toRem(35px);
  text-align: center;
  padding-top: toRem(70px);
  position: relative;
  z-index: 4;
  background: var(--background-color);

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    object-position: center;

    @media (max-width: 960px) {
      opacity: .3;
    }
  }

  &__logo-mobile {
    color: var(--main-color);
    margin: toRem(-66px) 0 toRem(24px);
    display: none;
    
    @media (max-width: 960px) {
      display: block;
    }
    
    & > svg {
      height: toRem(42px);
      width: auto;
    }
  }

  &__title {
    font-size: toRem(78px);
    color: var(--main-dark);
    font-weight: 700;
    line-height: 110%;

    @media (max-width: 768px) {
      font-size: toRem(64px);
    }

    @media (max-width: 660px) {
      font-size: toRem(41px);
    }

    @media (max-width: 390px) {
      font-size: toRem(38px);
    }

    span {
      background: var(--main-color);
      border-radius: toRem(7px);
      padding: 0 toRem(20px) toRem(11px);
      display: inline-flex;
      line-height: 90%;
      color: var(--text-contrast);
      position: relative;

      @media (max-width: 660px) {
        padding: toRem(2px) toRem(20px) toRem(7px);
      }

      i {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid var(--main-color);
        border-radius: toRem(7px);
        z-index: -1;
      }
    }
  }

  &__desc {
    margin-bottom: toRem(10px);
    font-size: toRem(21px);
    font-weight: 700;
    color: var(--main-dark);
    text-shadow: 1px 1px 3px rgb(229 233 243), -1px -1px 3px rgb(229 233 243);

    @media (max-width: 480px) {
      font-size: toRem(18px);
    }

    @media (max-width: 390px) {
      font-size: toRem(16px);
    }

    span {
      color: var(--gold);
    }
  }

  &__cards {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }

  &__card {
    aspect-ratio: 43/27;
    max-width: toRem(550px);
    width: 40vw;
    padding: toRem(58px) toRem(38px) toRem(36px);
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: toRem(35px);
    background: #f1f1f1;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    translate: -50% 30%;
    box-shadow: toRem(0px) toRem(24px) toRem(34px) rgba(0, 0, 0, 0.25);

    & > svg {
      position: absolute;
      bottom: 10%;
      right: 7%;
      height: 70%;
      width: auto;
      z-index: -1;
      opacity: .12;
    }

    @media (max-width: 1024px) {
      // width: toRem(320px);
      padding: toRem(28px) toRem(28px) toRem(26px);
      border-radius: toRem(20px);
    }

    @media (max-width: 540px) {
      width: toRem(220px);
      padding: toRem(24px) toRem(20px) toRem(20px);
      border-radius: toRem(15px);
    }

    &.--white {
      background: #F3F6FD;
      color: #149699;
      left: calc(50% - toRem(590px));
      @media (max-width: 1024px) {
        translate: 0;
        left: -20%;
        bottom: 10%;
      }
      rotate: -30deg;
    }

    &.--emerald {
      background: linear-gradient(270deg, #50D0CF 0%, #20ADAC 100%);
      left: calc(50% - toRem(300px));
      bottom: toRem(-80px);
      rotate: -3deg;
      @media (max-width: 1024px) {
        translate: 0;
        left: 15%;
        bottom: 0%;
      }
    }

    &.--gold {
      background: #C8A55E;
      left: calc(50% + toRem(190px));
      bottom: toRem(-160px);
      rotate: 8deg;
      @media (max-width: 1024px) {
        translate: 0;
        left: auto;
        right: 15%;
        bottom: -20%;
        z-index: 4;
      }
    }

    &.--black {
      background: linear-gradient(321deg, #131313 -7.93%, #2c2c2c 76.26%);
      left: calc(50% + toRem(590px));
      rotate: -8deg;
      @media (max-width: 1024px) {
        translate: 0;
        left: auto;
        right: -20%;
        bottom: -40%;
        z-index: 5;
      }
    }

    &--logo {
      & > svg {
        height: toRem(50px);
        width: auto;
        translate: 0 -15%;

        @media (max-width: 1024px) {
          height: toRem(32px);

          @media (max-width: 960px) {
            height: toRem(22px);
          }
        }
      }
      span {
        display: block;
        font-size: toRem(22.375px);

        @media (max-width: 1024px) {
          font-size: toRem(13px);
        }

        @media (max-width: 960px) {
          font-size: toRem(10px);
        }
        opacity: .4;
      }
    }

    &--number {
      margin-top: auto;
      margin-bottom: toRem(23px);
      font-size: toRem(33px);
      display: flex;
      align-items: center;
      gap: toRem(25px);
      font-weight: 500;

      @media (max-width: 1024px) {
        font-size: toRem(18px);
        gap: toRem(15px);
      }

      @media (max-width: 960px) {
        margin-bottom: toRem(15px);
        font-size: toRem(13px);
        gap: toRem(10px);
      }
    }

    &--info {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      align-items: center;
      gap: toRem(25px);
    }

    &--block {
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      span {
        opacity: 0.8;
        font-size: toRem(16.545px);

        @media (max-width: 1024px) {
          font-size: toRem(10px);
        }

        @media (max-width: 960px) {
          font-size: toRem(8px);
        }
      }

      big {
        font-size: toRem(22.375px);
        font-weight: 500;

        @media (max-width: 1024px) {
          font-size: toRem(12px);
        }

        @media (max-width: 960px) {
          font-size: toRem(9px);
        }
      }
    }
  }
}