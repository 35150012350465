:root {
  --background-color: #F0F0F8;
  --secondary-background-color: #F3F6FD;
  --icon-background-color: #E8EEFA;
  --programs-color: #586971;
  --programs-contrast-color: #EBF3FF;
  --text-color: #004141;
  --text-contrast: #FFFFFF;
  --main-color: #149699;
  --main-dark: #006261;
  --gradient-start: #20ADAC;
  --gradient-end: #50D0CF; 
  --gold: #C0A161;
  --shadow-up: rgba(255, 255, 255, 0.11);
  --shadow-down: rgba(180, 193, 213, 0.56);
  --geography-title: #434C55;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --background-color: #1b1b1d;
//     --text-color: #149699;
//     --text-contrast: #FFFFFF;
//     --main-color: #20ADAC;
//     --main-dark: #149699;
//     --gradient-start: #20ADAC;
//     --gradient-end: #50D0CF; 
//     --gold: #C0A161;
//     --shadow-up: rgba(255, 255, 255, 0.11);
//     --shadow-down: rgb(0, 0, 0);
//   }
// }