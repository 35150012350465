.banks {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .default-title {
    padding: 0 toRem(20px);

    @media (max-width: 768px) {
      font-size: toRem(26px)
    }
  }

  @media (max-width: 960px) {
    .default-title {
      order: -2;
    }
    &__bottom {
      order: -1;
    }
  }

  &__holder {
    overflow: visible;
    margin-top: toRem(52px);

    @media (max-width: 960px) {
      margin: 0;
    }

    @media (max-width: 768px) {
      overflow: scroll;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__row {
    display: flex;
    gap: toRem(60px);
    overflow: auto;
    padding: 0 toRem(220px);
    overflow: visible;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 0 toRem(15px);
      gap: toRem(20px);
      flex-direction: column;
    }
  }

  &__bottom {
    color: var(--text-contrast);
    font-weight: 700;
    font-size: toRem(36.933px);
    text-align: center;
    margin-top: toRem(100px);
    margin-bottom: toRem(-40px);

    br {
      display: none;
    }

    @media (max-width: 960px) {
      margin-top: toRem(34px);
      font-size: toRem(24px);
      margin-bottom: toRem(65px);
    }

    @media (max-width: 540px) {
      font-size: toRem(18px);

      br {
        display: initial;
      }
    }

    @media (max-width: 390px) {
      font-size: toRem(18px);
    }

    span {
      color: var(--gold);
    }
  }
}

.bank {
  max-width: toRem(590px);
  font-size: toRem(30px);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  color: var(--background-color);
  padding: toRem(65px) toRem(46px);
  background: rgba(255,255,255,.1);
  // white-space: nowrap;
  text-align: center;
  border-radius: toRem(55px);

  @media (max-width: 960px) {
    font-size: toRem(22px);
    border-radius: toRem(25px);
    padding: toRem(40px) toRem(40px);
  }

  @media (max-width: 540px) {
    font-size: toRem(18px);
    padding: toRem(40px) toRem(40px);
  }
}